var istoWrap = document.querySelector('.isto');
var menuToggle = document.getElementById('main-menu-toggle');
var mobileMenu = document.getElementById('mobile-menu');
var mobileMenuClose = document.getElementById('mobile-menu-close');
if (menuToggle && mobileMenuClose) {
    [menuToggle, mobileMenuClose].forEach(function (el) { return el.addEventListener('click', function () {
        istoWrap.classList.toggle('open');
        mobileMenu.classList.toggle('open');
    }); });
}
if (mobileMenu) {
    var submenus = Array.from(mobileMenu.querySelectorAll('.sub-menu'));
    submenus.forEach(function (menu) {
        var parent = menu.parentElement;
        var parentLink = parent.querySelector('a');
        var items = Array.from(menu.querySelectorAll('li'));
        if (parent && parentLink) {
            var href = parentLink.href.replace(/^https?:\/\/.+?\//, '').trim();
            if (href === '' || href === '#') {
            }
            parent.querySelector('a').addEventListener('click', function (event) {
                event.preventDefault();
                if (menu.classList.contains('open')) {
                    menu.style.maxHeight = '';
                    menu.classList.remove('open');
                    setTimeout(function () {
                        menu.style.display = '';
                    }, 301);
                }
                else {
                    menu.style.display = 'block';
                    menu.classList.add('open');
                    window.requestAnimationFrame(function () {
                        menu.style.maxHeight = items.reduce(function (acc, item, index) {
                            acc += 33.2 + (index > 0 ? 6 : 10);
                            return acc;
                        }, 0) + 'px';
                    });
                }
            });
        }
    });
}
