import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
var locale = window.__isto && window.__isto.currentLanguage && window.__isto.currentLanguage.default_locale
    ? window.__isto.currentLanguage.default_locale
    : 'en_US';
var region = locale.split('_')[1];
var language = locale.split('_')[0];
Vue.use(VueGoogleMaps, {
    load: {
        key: GMAPS_API_KEY,
        libraries: 'places',
        region: region,
        language: language,
    }
});
Vue.component('x-gmap-map', VueGoogleMaps.Map);
Vue.component('x-gmap-marker', VueGoogleMaps.Marker);
Vue.component('x-gmap-infowindow', VueGoogleMaps.InfoWindow);
Vue.component('x-gmap-cluster', GmapCluster);
