var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
__spreadArrays(Array.from(document.querySelectorAll('input')), Array.from(document.querySelectorAll('textarea')), Array.from(document.querySelectorAll('select'))).map(function (input) {
    if (input.dataset.novalidate !== 'on') {
        input.parentElement.classList.add('input');
        input.parentElement.classList.add("input--" + input.type);
        if (input.type === 'radio' || input.type === 'checkbox') {
            input.parentElement.insertBefore(document.createElement('span'), input.nextSibling);
        }
        input.addEventListener('input', function (input) {
            var el = input.target;
            el.classList.remove('valid', 'invalid');
            if (el.value.trim() !== '' || el.required) {
                el.classList.add(el.validity.valid ? 'valid' : 'invalid');
            }
        });
    }
});
