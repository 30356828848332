import { doIfSelector } from './util/doIfSelector';
import './general/inputs';
import './general/navigation';
import './vue/vue2-google-maps';
doIfSelector('.js-masonry', function () { return import('./components/news/masonry'); });
doIfSelector('.js-post-grid', function () { return import('./components/events'); });
doIfSelector('.js-scroller', function () { return import('./components/scroller'); });
doIfSelector('.js-scroll-here', function () { return import('./components/scroll-here'); });
doIfSelector('[data-app="members.overview-map"]', function () { return import('./components/members/overview-map'); });
doIfSelector('[data-app="members.single-map"]', function () { return import('./components/members/single-map'); });
doIfSelector('[data-app="intl-events.overview-map"]', function () { return import('./components/intl-events/overview-map'); });
doIfSelector('[data-app="intl-events.single-map"]', function () { return import('./components/intl-events/single-map'); });
doIfSelector('[data-app="slider"]', function () { return import('./components/slider/slider'); });
